import { Images } from "assets/index";
import styles from "./Loading.module.sass";

export const Loading = () => {
  return (
    <div className={styles.container}>
      <div className={styles.imgContainer}>
        <img
          className={styles.img}
          alt="logo techtime"
          src={Images.LogoTechtime}
        />
      </div>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
