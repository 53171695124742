import { InternalRouter } from "./InternalRouter";
import { QueryClientProvider } from "@tanstack/react-query";
import { QueryClient } from "services/QueryClient";
import { I18nextProvider } from "react-i18next";
import Lang from "./Lang";
import { Suspense } from "react";
import { RecoilRoot } from "recoil";

const App = () => {
  return (
    <I18nextProvider i18n={Lang}>
      <QueryClientProvider client={QueryClient}>
        <RecoilRoot>
          <Suspense fallback={<p>Loading...</p>}>
            <InternalRouter />
          </Suspense>
        </RecoilRoot>
      </QueryClientProvider>
    </I18nextProvider>
  );
};

export default App;
