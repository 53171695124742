import { useQuery } from "@tanstack/react-query";
import { APIError } from "models/Api";
import { PublicShare } from "models/PublicShare";
import { getPublicShare } from "services/Api";

export const usePublicShare = (slug: string | null, enabled = true) => {
  const {
    data: publicShare,
    status,
    error,
    isError,
    refetch,
  } = useQuery<unknown, APIError, PublicShare>(
    ["PublicShare", slug],
    async () => {
      return getPublicShare(slug || "");
    },
    {
      enabled: !!slug && enabled,
    }
  );

  return {
    refetch,
    publicShare,
    isLoading: status === "loading",
    error,
    isError,
  };
};
