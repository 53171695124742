import { PublicShare } from "models/PublicShare";
import { atom, selector } from "recoil";

export const PublicShareState = atom<PublicShare | null>({
  key: "PublicShareState",
  default: null,
});

export const PublicStateSelector = selector({
  key: "PublicShareSelector",
  get: ({ get }) => get(PublicShareState),
});

export const ShowUnavailabilitiesSelector = selector({
  key: "ShowUnavailabilitiesSelector",
  get: ({ get }) => {
    const share = get(PublicShareState);
    if (!share) return false;
    return (
      (share.options?.show_unavailabilities ?? false) &&
      (share.options?.show_employees ?? false)
    );
  },
});

export const ShowEmployeesSelector = selector({
  key: "ShowEmployeesSelector",
  get: ({ get }) => {
    const share = get(PublicShareState);
    if (!share) return false;
    return share.options?.show_employees ?? false;
  },
});

export const ShowEventHoursSelector = selector({
  key: "ShowEventHoursSelector",
  get: ({ get }) => {
    const share = get(PublicShareState);
    if (!share) return false;
    return share.options?.show_event_hours ?? false;
  },
});

export const ShowProjectDateSelector = selector({
  key: "ShowEventDateSelector",
  get: ({ get }) => {
    const share = get(PublicShareState);
    if (!share) return false;
    return share.options?.show_project_dates ?? false;
  },
});

export const ShowReasonsSelector = selector({
  key: "ShowReasonsSelector",
  get: ({ get }) => {
    const share = get(PublicShareState);
    if (!share) return false;
    return share.options?.show_reasons ?? false;
  },
});

export const ShowFullNameSelector = selector({
  key: "ShowFullNameSelector",
  get: ({ get }) => {
    const share = get(PublicShareState);
    if (!share) return false;
    return share.options?.show_full_name ?? false;
  },
});
