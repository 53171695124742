import axios, { isAxiosError } from "axios";
import { formatISO } from "date-fns";
import { APIError } from "models/Api";
import { Project } from "models/Project";
import { PublicShare } from "models/PublicShare";
import { Event } from "models/Event";
import { Unavailability, UnavailabilityReason } from "models/Unavailability";
import { parsingSlug } from "./QueryString";
import Configuration from "../Configuration";
import { Employee } from "models/Employee";
import { SelectOption } from "recoils/EmployeeSelectAtom";

export const axiosApi = axios.create({
  baseURL: Configuration.value("backendHost"),
});

axiosApi.interceptors.request.use(
  (evt) => {
    const slug = parsingSlug();
    if (!slug) return Promise.resolve(evt);
    const passwordInLocalStorage = sessionStorage.getItem(
      `PublicShare:${slug}`
    );
    if (!passwordInLocalStorage) return Promise.resolve(evt);
    const authorizationValue = btoa(`:${passwordInLocalStorage}`);
    evt.headers.setAuthorization(`Basic ${authorizationValue}`);
    return Promise.resolve(evt);
  },
  (err) => {
    return Promise.reject(err);
  }
);

axiosApi.interceptors.response.use(
  (evt) => {
    return Promise.resolve(evt);
  },
  (e) => {
    if (isAxiosError(e)) {
      return Promise.reject(new APIError(e.response?.status || 0));
    }
    return Promise.reject(new APIError(0));
  }
);

export const getPublicShare = async (slug: string) => {
  const rsp = await axiosApi.get<PublicShare>(`/api/v2/shares/${slug}`);
  return rsp.data;
};

export const getProject = async (slug: string) => {
  const rsp = await axiosApi.get<Project>(`/api/v2/shares/${slug}/projects`);
  return rsp.data;
};

type EventParams = {
  "search_params[after_eq_date]": string;
  "search_params[before_eq_date]": string;
  "search_params[employee_ids][]"?: Array<Number>;
};

export const getEvent = async (
  slug: string,
  timeRange: [Date, Date],
  employeesIds: readonly SelectOption[]
) => {
  const params: EventParams = {
    "search_params[after_eq_date]": formatISO(timeRange[0]),
    "search_params[before_eq_date]": formatISO(timeRange[1]),
  };
  params["search_params[employee_ids][]"] = employeesIds.map((x) => x.value);
  const rsp = await axiosApi.get<Event>(`/api/v2/shares/${slug}/events`, {
    params: params,
  });
  return rsp.data;
};

export const getUnavailabilities = async (slug: string, timeframe: string) => {
  const rsp = await axiosApi.get<Unavailability>(
    `/api/v2/shares/${slug}/unavailabilities`,
    {
      params: {
        "search_params[time_frame]": timeframe,
      },
    }
  );
  return rsp.data;
};

export const getUnavailabilitiesReasons = async (slug: string) => {
  const rsp = await axiosApi.get<UnavailabilityReason>(
    `/api/v2/shares/${slug}/unavailabilities/reasons`
  );
  return rsp.data;
};

export const getEmployees = async (slug: string) => {
  const rsp = await axiosApi.get<Employee[]>(
    `/api/v2/shares/${slug}/employees`
  );
  return rsp.data;
};
