import { QueryClient as QueryClientRQ } from "@tanstack/react-query";

export const QueryClient = new QueryClientRQ({
  defaultOptions: {
    queries: {
      retry: false,
      refetchInterval: 60000,
    },
  },
});
