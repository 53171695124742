const PATH_REGEX = /^\/([\w]+)$/;

export const parsingSlug = () => {
  const { pathname } = window.location;

  const slug = pathname.match(PATH_REGEX);

  if (!slug) return null;

  return slug[1];
};
