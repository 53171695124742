import i18next from "i18next";
import FR from "./Translation/fr.json";
import DE from "./Translation/de.json";
import EN from "./Translation/en.json";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { fr, de, enUS } from "date-fns/locale";
import { format as formatDate, isDate } from "date-fns";

export const dateLocales = {
  fr: fr,
  de: de,
  en: enUS,
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      caches: ["cookie", "localStorage"],
      cookieDomain: "techtime.fr",
      lookupCookie: "techtimeLang",
    },
    resources: {
      fr: {
        translation: FR,
      },
      de: {
        translation: DE,
      },
      en: {
        translation: EN,
      },
    },
    fallbackLng: "fr",
    interpolation: {
      escapeValue: false,
      format: (value: unknown, format, lng = "fr") => {
        if (isDate(value) && format) {
          const currentLocale = dateLocales[lng as keyof typeof dateLocales];
          return formatDate(value as Date, format, { locale: currentLocale });
        }
        return value as string;
      },
    },
  });

export default i18next;
