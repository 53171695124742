import { usePublicShare } from "hooks/PublicShareHook";
import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { PublicShareState } from "recoils/PublicShareAtom";
import { SlugState } from "recoils/SlugAtom";
import { parsingSlug } from "services/QueryString";
import { Loading } from "views/Loading";

const Events = React.lazy(() => import("views/Events"));
const Projects = React.lazy(() => import("views/Projects"));
const Unavailabilities = React.lazy(() => import("views/Unavailabilities"));
const Password = React.lazy(() => import("views/Password"));
const NotFound = React.lazy(() => import("views/NotFound"));

export const InternalRouter = () => {
  const slug = parsingSlug();
  const { error, isError, isLoading, publicShare } = usePublicShare(slug);
  const [_, setSlug] = useRecoilState(SlugState);
  const [__, setPublicShare] = useRecoilState(PublicShareState);

  useEffect(() => {
    if (slug) setSlug(slug);
  }, [slug]);

  useEffect(() => {
    if (publicShare) setPublicShare(publicShare);
  }, [publicShare]);

  if (!slug) return <NotFound />;
  if (isLoading) return <Loading />;
  if (isError && error && error.statusCode === 404) return <NotFound />;
  if (isError && error && error.statusCode === 401)
    return <Password slug={slug} />;

  if (publicShare?.share_type === "global") return <Events />;
  if (publicShare?.share_type === "intervention") return <Events />;
  if (publicShare?.share_type === "dockyard") return <Projects />;
  if (publicShare?.share_type === "unavailabilities")
    return <Unavailabilities />;

  return <Loading />;
};
