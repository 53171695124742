import { atom, selector } from "recoil";

export const SlugState = atom({
  key: "SlugState",
  default: "",
});

export const SlugSelector = selector({
  key: "SlugSelector",
  get: ({ get }) => get(SlugState),
});
